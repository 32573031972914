/* Courtesy of http://react-autosuggest.js.org/ */

import React from "react"
import Autosuggest from "react-autosuggest"
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(data, value) {
  const escapedValue = escapeRegexCharacters(value.trim());
  
  if (escapedValue.length < 3) {
    return [];
  }

  const regex = new RegExp('\\b' + escapedValue, 'ig');

  return data
    .map(section => {
      return {
        title: section.fieldValue,
        suggestions: section.nodes.filter(empl => regex.test(empl.name))
      };
    })
    .filter(section => section.suggestions.length > 0);
}

// When selected by mouse or keyboard, set URL to appropriate table row and go
function onSuggestionSelected(event, {suggestion}) {
  var url = window.location.href;
  var slash = url.substr(url.length - 1) === '/' ? '' : '/';
  window.location.href = url + slash + suggestion.org + '#' + suggestion.name;
}

// What is shown in input box when suggestion is highlighted
function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <>
      <span> {suggestion.name} </span> <br />
      <span className="silver f6"> {suggestion.title}</span>
    </>
  );
}

function renderSectionTitle(section) {
  return (
    <span className="f6 fw7 black">{section.title}</span>
  );
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

class AutosuggestSearch extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };    
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(this.props.data, value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest 
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps} />
    );
  }
}

export default AutosuggestSearch