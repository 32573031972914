import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import DataTable from "react-data-table-component"
import Dropdown from "react-dropdown"
import 'react-dropdown/style.css'
import _ from "underscore"
import AutosuggestSearch from "../components/autosuggest-search"

export default ({ data }) => {

  const [year, setYear] = React.useState('2023');

  const yearData = data.allPayrollCsv.nodes.filter(o => o.CalYear === year);
  const employmentCounts = _.countBy(yearData, 'Org');

  const tableData = Object.keys(employmentCounts).map(org => {return {
    'Org': org,
    'Employment': employmentCounts[org]
  }});

  const topEmployee = _.max(yearData, function(o) {return parseInt(o.Salary) + parseInt(o.Fringe)})

  const totalOrgs = tableData.filter(z => z.Employment > 0).length;
  const totalEmployment = _.values(employmentCounts).reduce((a, b) => a + b, 0);

  const z = yearData.map(function(o) { return {
    'org': o.Org,
    'name': o.FirstName + ' ' + o.LastName,
    'title': o.Title,
  } } );

  const z_grouped = _.pairs(_.groupBy(z, 'org'));

  const employeeSearchData = z_grouped.map(x => ({
    'fieldValue': x[0],
    'nodes': x[1]
    })
  )

  return (
    <Layout>

      <div className="cf pv4">
        <h1 className="f1 fw3 ma0 fl">Payroll</h1>

        <Dropdown
          options={['2019', '2020', '2021', '2022', '2023']}
          onChange={(v) => setYear(v.value) }
          className='mw4 fl mv2 ml4'
          placeholder={year}
          value={year}
        />

        <h4 className="fl f4 fw3 mv3 ml2">calendar year</h4>
      </div>

      <p className="f4 pt2 pb3">
        The table below shows total number of people employed by an organization, by year.
        Click on an organization to view its demographic data and individual employee compensation.
      </p>

      <div className="f4 w-100 w-third-l pa3 bg-near-white fl br bw5 b--white mb3">
        <h4 className="f4 mt0 mb2">Payroll Summary</h4>

        <h5 className="f5 mb1">Total Employment</h5>
        <span className="pl2">{totalEmployment.toLocaleString()}</span>

        <h5 className="f5 mb1">Total Organizations</h5>
        <span className="pl2">{totalOrgs}</span>

        <h5 className="f5 mb1">Highest-Paid Employee</h5>
        <ul className="list f5 pl2 mt0 lh-copy">
          <li>{topEmployee.FirstName} {topEmployee.LastName}</li>
          <li>${parseInt(topEmployee.Salary + topEmployee.Fringe).toLocaleString()}</li>
          <li>{topEmployee.Org}</li>
        </ul>
      </div>

      <div className="w-100 w-two-thirds-l fl pv3 ph2 ph0-l">

        <h4 className="f4 ma0">Search an Employee</h4>

        <div className="mt2 mb4">
          <AutosuggestSearch data={employeeSearchData} placeholder='Type name' />
        </div>

        <h4 className="f4 ma0">Employment by Organization</h4>

        <DataTable

          title="Payroll by Fiscal Year"
          keyField='Organization'
          noHeader

          defaultSortField='Org'

          columns={[
            {
              name: 'Organization',
              selector: 'Org',
              sortable: true,
              grow: 5,
              format: x => <Link to={'/payroll/' + x.Org} className="link black underline-hover">{x.Org}</Link>
            },
            {
              name: 'Employees',
              selector: 'Employment',
              sortable: true,
              right: true,
            }
          ]}

          data={tableData}

          customStyles={{
            cells: {
              style: {
                paddingLeft: '0'
              }
            },
            headCells: {
              style: {
                fontSize: '13px',
                paddingLeft: '0',
                fontWeight: 'bold',
              }
            },
            rows: {
              style: {
                fontSize: '15px'
              }
            }
          }}
        />

      </div>

    </Layout>
  )

}

export const query = graphql`
  query Payroll {
    allPayrollCsv {
      nodes {
        Org
        CalYear
        FirstName
        LastName
        Salary
        Fringe
        FullPart
        Title
      }
    }
  }
`
